import React from "react";
import Heading from "../components/common/Heading";
import Sliders from "../components/home/Slider";

export default function Testimonial() {
  return (
    <>
      <Heading heading="What Our Clients Say" title="Home" subtitle="What Our Clients Say" />
      <Sliders />
    </>
  );
}
