// ContactUsHandler.js 

import { db } from "../../../model/FirebaseConfig";
import { collection, doc, setDoc, getDocs, query, orderBy, limit } from "firebase/firestore";

// Function to generate a new ID based on the last document's ID
async function generateNewContactUsId() {
  const contactUsRef = collection(db, "ContactUs");
  const q = query(contactUsRef, orderBy("id", "desc"), limit(1));
  
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const lastDoc = querySnapshot.docs[0];     
    const lastId = lastDoc.id;
    
    // Extract the numeric part of the last ID and increment by 1
    const lastNumericId = parseInt(lastId.split("-")[1]);
    const newId = lastNumericId + 1;
    
    // Pad the new ID with leading zeros to maintain the format "CUS-XXX"
    return `CUS-${newId.toString().padStart(3, "0")}`;
  } else {
    // If there are no documents, start with "CUS-001"
    return "CUS-001";
  }
}

// Function to save the contact form submission with a dynamically generated ID
export async function saveContactUsSubmission(name, email, message) {
  const newDocId = await generateNewContactUsId();
  const docRef = doc(db, "ContactUs", newDocId);

  try {
    await setDoc(docRef, { id: newDocId, name, email, message, submittedAt: new Date() });
  } catch (error) {
    console.error("Error saving contact form submission:", error);
    throw error;
  }
}
