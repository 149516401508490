import React from "react";
import Heading from "../components/common/Heading";
import Book from "../components/home/Book";
import CommonHeading from "../components/common/CommonHeading";

export default function Booking() {
  return (
    <>
      <Heading heading="Booking" title="Home" subtitle="Booking" />
      <CommonHeading
            heading="Inquiry"
            subtitle="Booking"
            title="For Any Query"
          />
      <Book/>
    </>
  );
}
