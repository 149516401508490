'use client'

import React, { useState, useRef, useEffect } from 'react'

// Assuming these imports are correct and the data is available
import { Terms_And_Conditions, Privacy_Policy } from "../data/Data.jsx"

export default function Policies() {
  const [selectedPolicy, setSelectedPolicy] = useState('terms')
  const [isReadableMode, setIsReadableMode] = useState(false)
  const scrollAreaRef = useRef(null)

  const policies = {
    terms: {
      title: "Terms and Conditions",
      data: Terms_And_Conditions
    },
    privacy: {
      title: "Privacy Policy",
      data: Privacy_Policy
    }
  }

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = 0
    }
  }, [selectedPolicy])

  return (
    <div className="min-vh-100 bg-light py-5" style={{ backgroundColor: '#e8f5e9' }}>
      <div className="container">
        <h1 className="display-4 text-center text-success mb-5">Policies and Guidelines</h1>
        
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card shadow">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${selectedPolicy === 'terms' ? 'active' : ''}`}
                      onClick={() => setSelectedPolicy('terms')}
                    >
                      Terms & Conditions
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${selectedPolicy === 'privacy' ? 'active' : ''}`}
                      onClick={() => setSelectedPolicy('privacy')}
                    >
                      Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
              
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="h4 text-success mb-0">{policies[selectedPolicy].title}</h2>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="readableModeSwitch"
                      checked={isReadableMode}
                      onChange={() => setIsReadableMode(!isReadableMode)}
                    />
                    <label className="form-check-label" htmlFor="readableModeSwitch">
                      Readable Mode
                    </label>
                  </div>
                </div>
                
                <div 
                  ref={scrollAreaRef}
                  style={{ 
                    maxHeight: '60vh', 
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#198754 #e9ecef'
                  }}
                >
                  {policies[selectedPolicy].data.map((item, index) => (
                    <article key={index} className={`mb-4 ${isReadableMode ? 'readable-mode' : ''}`}>
                      <h3 className="h5 text-success">{item.title}</h3>
                      <p className={isReadableMode ? 'lead' : ''}>{item.description}</p>
                    </article>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}