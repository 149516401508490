import React from 'react'
import Policies from '../components/home/Policies';
import Heading from "../components/common/Heading";
import Sliders from "../components/home/Slider";

function PoliciesPage() {
  return (
    <>
    <Heading heading="Policies" title="Home" subtitle="Policies" />
    <Policies/>
    <Sliders />
    </>
  )
}

export default PoliciesPage