import React, { useState } from "react";
import saveInquiry from './utilities/bookHandler';

export default function Book() {
  const [formData, setFormData] = useState({
    fullName: "",
    serviceType: "",
    phoneNumber: "",
    note: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable button on submit
    const { fullName, serviceType, phoneNumber, note } = formData;

    const success = await saveInquiry(fullName, serviceType, phoneNumber, note);
    if (success) {
      // Clear the form after successful submission
      setFormData({
        fullName: "",
        serviceType: "",
        phoneNumber: "",
        note: "",
      });
    }
    setIsSubmitting(false); // Re-enable button after submission
  };

  return (
    <div
      className="container-fluid booking pb-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container">
        <div className="bg-white shadow" style={{ padding: "35px" }}>
          <h3 className="text-success mb-4">Make an Inquiry</h3>
          <form onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <select
                  className="form-select"
                  name="serviceType"
                  value={formData.serviceType}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Type of Service
                  </option>
                  <option value="Flight Booking">Flight Booking</option>
                  <option value="Hotel Booking">Hotel Booking</option>
                  <option value="Vacation Package">Vacation Package</option>
                  <option value="Car Rental">Car Rental</option>
                  <option value="Event Planning">Event Planning</option>
                </select>
              </div>
              <div className="col-md-6">
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  placeholder="Type your Inquiry here"
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-md-12">
                <button type="submit" className="btn btn-success w-100" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
