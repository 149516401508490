import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// If you need analytics, import getAnalytics
// import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyD8Jz_ojU6M3N7iGJ-K8XUrYXr85g9crUY",
    authDomain: "omac-groups.firebaseapp.com",
    projectId: "omac-groups",
    storageBucket: "omac-groups.appspot.com",
    messagingSenderId: "833696910575",
    appId: "1:833696910575:web:70f58e6105e40a61565f1a",
    measurementId: "G-0SM17FFE89"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Uncomment this if analytics are needed and correctly set up
// const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export { db };
