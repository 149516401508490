export const navList = [
  {
    id: 1,
    path: "/",
    text: "Home",
  },
  {
    id: 2,
    path: "/about",
    text: "About",
  },
  {
    id: 3,
    path: "/services",
    text: "Services",
  },
  // {
  //   id: 4,
  //   path: "/rooms",
  //   text: "Rooms",
  // },
  {
    id: 5,
    path: "/page",
    text: "Page",
    subItems: [
      {
        id: 51,
        path: "/booking",
        text: "Booking",
      },
      {
        id: 52,
        path: "/team",
        text: "Our Team",
      },
      {
        id: 53,
        path: "/testimonial",
        text: "What Our Clients Say",
      },
    ],
  },
  {
    id: 6,
    path: "/contact",
    text: "Contact",
  },
];
export const socialIcons = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
    link: "https://www.facebook.com/share/xEQciufpVfmz2asK/?mibextid=LQQJ4d",
  },
  // {
  //   icon: <i className="fab fa-twitter"></i>,
  //   link: "https://twitter.com/your-twitter-handle",
  // },
  {
    icon: <i className="fab fa-instagram"></i>,
    link: "https://www.instagram.com/omacgroups?igsh=ZXRtNGxvcDFnODEy",
  },
  {
    icon: <i className="fab fa-linkedin-in"></i>,
    link: "https://www.linkedin.com/in/your-linkedin-profile",
  },
  // {
  //   icon: <i className="fab fa-youtube"></i>,
  //   link: "https://www.youtube.com/c/your-channel-name",
  // },
];

export const carouselData = [
  {
    img: "../assets/omac/coverbg2.jpeg",
    title: "Discover the Premium Experience with OMAC GROUPS",
    subtitle: "Luxury in Air Travel",
    btn1: "Our Services",
    btn2: "Book Your Flight",
    path1: "/booking",
    path2: "/services",
  },
  {
    img: "../assets/omac/coverbg1.jpeg",
    title: "Discover the Premium Experience with OMAC Groups",
    subtitle: "Luxury in Air Travel",
    btn1: "Our Services",
    btn2: "Book Your Flight",
    path1: "/booking",
    path2: "/services",
  },
];
export const about = [
  {
    icon: <i className="fa fa-plane fa-2x text-success mb-2"></i>, // Air Ticketing
    text: "Air Ticketing",
    // count: "Competitive Pricing",  // Emphasizing competitive pricing
  },
  {
    icon: <i className="fa fa-truck fa-2x text-success mb-2"></i>, // Courier Services
    text: "Courier Services",
    // count: "Fast & Secure Delivery",  // Highlighting service reliability
  },
  {
    icon: <i className="fa fa-passport fa-2x text-success mb-2"></i>, // Visa Services
    text: "Visa Services",
    // count: "Timely Approvals",  // Focus on efficiency
  },
  {
    icon: <i className="fa fa-bed fa-2x text-success mb-2"></i>, // Hotel Booking
    text: "Hotel Booking",
    // count: "Exclusive Deals",  // Emphasizing partnerships for better offers
  },
  {
    icon: <i className="fa fa-globe fa-2x text-success mb-2"></i>, // Inbound and Outbound Tours
    text: "Tours",
    // count: "Tailored Itineraries",  // Highlighting customization
  },
  {
    icon: <i className="fa fa-car fa-2x text-success mb-2"></i>, // Rent a Car for Tourism
    text: "Car Rentals",
    // count: "Diverse Fleet Available",  // Emphasizing vehicle options
  },
  {
    icon: <i className="fa fa-shield-alt fa-2x text-success mb-2"></i>, // Travel Insurance
    text: "Travel Insurance",
    // count: "Comprehensive Coverage",  // Highlighting extensive protection
  },
];

export const services = [
  {
    icon: <i class="fa fa-plane fa-2x text-success"></i>,
    name: "Air Ticketing",
    discription:
      "We provide comprehensive air ticketing services with competitive pricing and flexible options for domestic and international flights. Our team ensures a seamless booking experience tailored to your travel needs.",
  },
  {
    icon: <i class="fa fa-box fa-2x text-success"></i>,
    name: "Courier Services",
    discription:
      "Our reliable courier services offer fast, secure delivery of documents and packages locally and internationally. With real-time tracking, we guarantee safe and timely delivery.",
  },
  {
    icon: <i class="fa fa-passport fa-2x text-success"></i>,
    name: "Visa Services",
    discription:
      "OMAC Groups provides complete visa assistance, guiding you through document preparation and application submission, ensuring timely approvals for your travel.",
  },
  {
    icon: <i class="fa fa-hotel fa-2x text-success"></i>,
    name: "Hotel Booking",
    discription:
      "We offer a wide range of hotel booking options to suit all budgets, from luxury to economical. Enjoy exclusive deals and personalized service through our global hotel partnerships.",
  },
  {
    icon: <i class="fa fa-globe fa-2x text-success"></i>,
    name: "Inbound and Outbound Tours",
    discription:
      "Discover curated tours that cover local attractions and international adventures. Our tailored itineraries guarantee memorable experiences with expert guidance.",
  },
  {
    icon: <i class="fa fa-car fa-2x text-success"></i>,
    name: "Rent a Car for Tourism",
    discription:
      "Enhance your travel with our car rental services designed for tourism. Choose from a fleet of vehicles and flexible rental options, with knowledgeable drivers available.",
  },
  {
    icon: <i class="fa fa-shield-alt fa-2x text-success"></i>,
    name: "Travel Insurance",
    discription:
      "Protect your travel investment with comprehensive insurance covering trip cancellations, medical emergencies, and lost luggage, ensuring peace of mind throughout your journey.",
  },
  {
    icon: <i class="fa fa-hands-helping fa-2x text-success"></i>,
    name: "Pilgrimages",
    discription:
      "Embark on your spiritual journey with our specialized Hajj and Umrah travel packages. We provide tailored services to make your pilgrimage smooth, comfortable, and fulfilling.",
  },
];

export const Privacy_Policy = [
  {
    title: "Privacy Policy",
    description:
      "At OMAC Groups, customer satisfaction is our top priority. We recognize the trust our customers place in us, and we are committed to protecting your personal information with the utmost care. We understand that handling your information responsibly is key to providing a seamless and enjoyable experience. OMAC Groups manages your personal information in accordance with the applicable Information Privacy Principles (IPPs) under the laws governing data protection and privacy. We are fully committed to ensuring the security and privacy of any information collected while you use our services. Below, we outline our practices for collecting, using, and securely storing your information.",
  },
  {
    title: "Information We Collect",
    description:
      "We collect personal information that is necessary to provide our services and enhance your experience. This may include: Contact Details, such as your name, email address, phone number, and mailing address; Identification Information, including driver's license or passport details for verification and compliance; Payment Information, for processing payments securely, including credit/debit card information (processed through secure third-party payment providers); and Usage Data, information about your interactions with our site to help improve our services and enhance user experience.",
  },
  {
    title: "How We Use Your Information",
    description:
      "OMAC Groups uses your information solely to facilitate your experience and to fulfill our obligations to you. Specifically, we use your data to confirm and manage your bookings, including rental agreements and tour arrangements; communicate with you regarding booking confirmations, inquiries, and any updates or changes to your service; personalize your experience and suggest relevant services or packages; process payments securely and manage your account.",
  },
  {
    title: "Information Sharing and Disclosure",
    description:
      "OMAC Groups does not share your personal information with third parties, except when: necessary to complete a booking with trusted partners (e.g., third-party operators involved in a tour); required by law or regulatory authorities to protect your rights, our company, or to comply with legal processes; or authorized by you for specific purposes.",
  },
  {
    title: "Data Security and Storage",
    description:
      "We employ robust security measures to safeguard your personal information from unauthorized access, alteration, or disclosure. Your information is stored securely, and we limit access to authorized personnel only. Any sensitive information, such as payment details, is encrypted and processed through trusted third-party payment providers.",
  },
  {
    title: "Changes to Our Privacy Policy",
    description:
      "This policy may be updated periodically to reflect changes in regulations or adjustments to our services. We encourage you to review this policy regularly for updates. By using our services, you agree to the terms outlined in this Privacy Policy. For questions about our privacy practices, please contact us through our official channels.",
  },
];

export const Terms_And_Conditions = [
  {
    title: "Terms and Conditions",
    description:
      "These Terms and Conditions ('Terms') govern the relationship between the customer ('Passenger/Customer') and OMAC Groups, related to all car rentals and tour packages provided by our company. Please review these Terms carefully. By using our services, you confirm that you are at least 18 years of age and agree to be bound by the following Terms and Conditions. These Terms apply to all bookings made with OMAC Groups and cover all products and services you receive from us.",
  },
  {
    title: "1. General",
    description:
      "By making a booking, you agree to comply with these Terms. These Terms apply to any bookings made with us (online, over the phone, or by visiting our office). References to 'OMAC Groups,' 'we,' 'us,' or 'our' refer to OMAC Groups and its affiliates. References to 'you' and 'your' refer to the customer.",
  },
  {
    title: "2. Booking Process and Authority",
    description:
      "The person making the booking is responsible for acting on behalf of all travelers on the booking and will be considered as accepting these Terms on their behalf.",
  },
  {
    title: "3. Requirements for Travel",
    description:
      "Identification: Customers must present a valid driver’s license, passport, or other government-issued ID for verification upon booking and at the time of service. Age Requirements: All renters and drivers must meet the minimum age requirement specified by OMAC Groups.",
  },
  {
    title: "4. Services We Provide",
    description:
      "OMAC Groups offers car rentals, guided tours, and various travel packages. All services are subject to availability and may have additional provider-specific conditions.",
  },
  {
    title: "5. Travel Insurance",
    description:
      "OMAC Groups strongly recommends that all customers purchase travel insurance to cover cancellations, medical expenses, and other unforeseen circumstances.",
  },
  {
    title: "6. Health and Safety",
    description:
      "Passengers are responsible for ensuring they meet any health and safety requirements for the tours or destinations they book.",
  },
  {
    title: "7. Payments and Deposits",
    description:
      "Deposit and Final Payment: A deposit may be required at the time of booking, with the remaining balance due before the start date of the service. Payment Methods: We accept payments via credit card, debit card, or bank transfer. Fees may apply for certain payment methods.",
  },
  {
    title: "8. Changes and Cancellations",
    description:
      "Customer-Initiated Changes or Cancellations: Cancellations or booking changes may be subject to fees. Refund eligibility depends on how far in advance the cancellation is made. Provider Changes and Cancellations: In rare cases, OMAC Groups or third-party providers may need to cancel or reschedule services due to unforeseen circumstances. Customers will be notified and offered alternative options or a refund if applicable.",
  },
  {
    title: "9. No Show",
    description:
      "Failure to arrive at the scheduled time and location may result in cancellation without refund.",
  },
  {
    title: "10. Refund Policy",
    description:
      "Refunds will be processed according to the terms of each booking and may be subject to fees or deductions based on our and our partners' policies.",
  },
  {
    title: "11. Unavoidable Circumstances",
    description:
      "OMAC Groups is not liable for service disruptions caused by unavoidable circumstances, including but not limited to natural disasters, government actions, or public health issues.",
  },
  {
    title: "12. Privacy and Data Security",
    description:
      "We are committed to protecting your personal information in compliance with privacy laws. Please review our Privacy Policy for more details on how we handle your data.",
  },
  {
    title: "13. Liability",
    description:
      "Our Responsibility: OMAC Groups will not be liable for any loss, injury, or damage to persons or property arising from service providers or other third parties, except in cases of direct negligence on our part. Third-Party Liability: We are not responsible for changes made by third-party providers, including delays, cancellations, or missed connections.",
  },
  {
    title: "14. Baggage and Personal Belongings",
    description:
      "OMAC Groups is not responsible for the loss, theft, or damage of personal belongings during rentals or tours.",
  },
  {
    title: "15. Pricing and Payment Terms",
    description:
      "All pricing is subject to change and availability. Additional fees may apply for extra services, such as insurance, toll fees, or late return fees.",
  },
  {
    title: "16. Modifications of Terms",
    description:
      "OMAC Groups reserves the right to modify these Terms at any time. Customers should review these Terms periodically, as they are bound by the latest version.",
  },
  {
    title: "17. Governing Law",
    description:
      "These Terms and Conditions are governed by the laws of Sri Lanka, and all disputes will be subject to the exclusive jurisdiction of the courts in that location.",
  },
];

export const team = [
  {
    image: "../assets/omac/omar.jpg",
    name: "Mohamed Omar",
    designation: "Director",
  },
  {
    image: "../assets/omac/Murshid1.jpeg",
    name: "M M M Murshid",
    designation: "Managing Director",
  },
  {
    image: "../assets/omac/sharad.jpg",
    name: "Sharadh Hamza",
    designation: "Senior Travel Consultant",
  },
  {
    image: "../assets/omac/sajad1.jpg",
    name: "R. Sajad Ahmed",
    designation: "Travel Executive",
  },
  {
    image: "../assets/omac/naqeeb.jpg",
    name: "Naqeeb Nabeel",
    designation: "Trainee Travel Executive",
  },
];

export const footerItem = [
  {
    id: 1,
    header: "Company",
    UnitItem: [
      {
        name: "About Us",
      },
      {
        name: "Contact Us",
      },
      {
        name: "Privacy Policy",
        path: "/Policies",
      },
      {
        name: "Terms & Condition",
        path: "/Policies",
      },
      {
        name: "Support",
      },
    ],
  },
  {
    id: 2,
    header: "Services",
    UnitItem: [
      {
        name: "Air Ticketing",
        path: "/services",
      },
      {
        name: "Courier Services",
        path: "/services",
      },
      {
        name: "Visa Services",
        path: "/services",
      },
      {
        name: "Hotel Booking",
        path: "/services",
      },
      {
        name: "Inbound and Outbound Tours",
        path: "/services",
      },
      {
        name: "Rent a Car for Tourism",
        path: "/services",
      },
      {
        name: "Travel Insurance",
        path: "/services",
      },
    ],
  },
];

export const footerContact = [
  {
    icon: <i className="fa fa-map-marker-alt me-3"></i>,
    name: "81 Colombo Road, Katunayake",
  },
  {
    icon: <i className="fa fa-phone-alt me-3"></i>,
    name: "031 222 4888 / 070 750 4888",
  },
  {
    icon: <i className="fa fa-envelope me-3"></i>,
    name: "info@flyomac.lk",
  },
];

export const contact = [
  {
    icon: <i class="fa fa-envelope-open text-success me-2"></i>,
    title: "Ticket Booking",
    email: "info@flyomac.lk",
  },
  {
    icon: <i class="fa fa-envelope-open text-success me-2"></i>,
    title: "Technical",
    email: "info@flyomac.lk",
  },
  {
    icon: <i class="fa fa-envelope-open text-success me-2"></i>,
    title: "General",
    email: "infoomacgroups@gmail.com",
  },
];
export const testimonial = [
  {
    description:
      "OMAC Groups provided an exceptional travel experience from start to finish. Their air ticketing service made booking seamless, and I couldn't be happier with the support I received throughout the process.",
    name: "Ayesha Fernando",
    profession: "Travel Blogger",
    icon: (
      <i className="fa fa-quote-right fa-3x text-success position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-1.jpg",
  },
  {
    description:
      "The courier services from OMAC Groups were incredibly reliable and efficient. My documents were delivered on time, and the tracking feature made the process even more convenient.",
    name: "Ravi Kumar",
    profession: "Business Owner",
    icon: (
      <i className="fa fa-quote-right fa-3x text-success position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-2.jpg",
  },
  {
    description:
      "I had a wonderful experience with OMAC Groups' visa assistance. They guided me through every step, ensuring that my application was submitted correctly and on time.",
    name: "Sara Malik",
    profession: "Frequent Traveler",
    icon: (
      <i className="fa fa-quote-right fa-3x text-success position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
];

export const roomItems = [
  {
    img: "../assets/img/room-1.jpg",
    price: "$110/night",
    name: "Junior Suit",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },

  {
    img: "../assets/img/room-2.jpg",
    price: "$110/night",
    name: "Executive Suite",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
  {
    img: "../assets/img/room-3.jpg",
    price: "$110/night",
    name: "Super Deluxe",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
];

export const facility = [
  {
    icon: <i class="fa fa-bed text-primary me-2"></i>,
    quantity: 3,
    facility: "bed",
  },
  {
    icon: <i class="fa fa-bath text-primary me-2"></i>,
    quantity: 2,
    facility: "bath",
  },
  {
    icon: <i class="fa fa-wifi text-primary me-2"></i>,
    facility: "Wifi",
  },
];
