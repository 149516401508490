import { useState } from "react";
import { saveNewsletterSubscription } from "./utilities/NewsletterHandler";
import Swal from "sweetalert2";
import { retry } from "@reduxjs/toolkit/query";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    if (email.trim() == "") {
      setErrorMessage("Please eneter a valid email adderess.");
      return;
    }

    try {
      await saveNewsletterSubscription(email);
      Swal.fire({
        icon: "success",
        title: "Thank you for subscribing!",
        text: "You've successfully subscribed to our newsletter.",
        confirmButtonColor: "#28a745",
      });
      setErrorMessage("");
      setEmail("");
      setEmail("");
    } catch (error) {
      setErrorMessage("Failed to subscribe. Please try again.");
      console.error("Error saving subscription:", error);
    }
  };

  return (
    <>
      <div
        className="container newsletter mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="row justify-content-center">
          <div className="col-lg-10 border rounded p-1">
            <div className="border rounded text-center p-1">
              <div className="bg-white rounded text-center p-5">
                <h4 className="mb-4">
                  Subscribe Our
                  <span className="text-success text-uppercase">
                    Newsletter
                  </span>
                </h4>
                <div
                  className="position-relative mx-auto"
                  style={{ maxWidth: "400px" }}
                >
                  <input
                    className="form-control w-100 py-3 ps-4 pe-5"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-success py-2 px-3 position-absolute top-0 end-0 mt-2 me-2"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                {errorMessage && (
                  <p className="text-danger mt-3">{errorMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
