import { Link } from "react-router-dom";
import { footerContact, footerItem, socialIcons } from "../data/Data";
import Newsletter from "../home/Newsletter";

export default function Footer() {
  return (
    <>
      <Newsletter />
      <div
        className="container-fluid bg-dark text-light footer wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container pb-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-4">
              <div className="bg-success rounded p-4">
                <Link to="/">
                  <h1 className="text-white text-uppercase mb-3">
                    OMAC GROUPS
                  </h1>
                </Link>
                <p className="text-white mb-0">
                  Discover seamless travel solutions with OMAC Groups, from air
                  ticketing to visa assistance, hotel bookings, and customized
                  tours. Trust us to make every journey stress-free, memorable,
                  and professionally managed.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <h6 className="section-title text-start text-success text-uppercase mb-4">
                Contact
              </h6>
              {footerContact.map((val, index) => (
                <p className="mb-2" key={index}>
                  {val.icon} {val.name}
                </p>
              ))}
             
              <div className="d-flex pt-2">
                {socialIcons.slice(0, 4).map((val, index) => (
                  <a
                    key={index}
                    className="btn btn-outline-light btn-social"
                    href={val.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {val.icon}
                  </a>
                ))}
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="row gy-5 g-4">
                {footerItem.map((section, sectionIndex) => ( 
                  <div className="col-md-6" key={sectionIndex}>
                    <h6 className="section-title text-start text-success text-uppercase mb-4"> 
                      {section.header}
                    </h6>
                    {section.UnitItem.map((item, itemIndex) => (
                      <a className="btn btn-link" href={item.path} key={itemIndex}>
                        {item.name}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ); 
}
