import React from "react";
import { testimonial } from "../data/Data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/style.css";

export default function Sliders() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust to one slide for tablets
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576, // Adjust to one slide for smaller devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container-xxl testimonial my-5 py-5 bg-dark">
      <div className="container">
        <h2 className="text-white text-center mb-4">What Our Clients Say</h2>
        <div className="testimonial-carousel py-5">
          <Slider {...settings}>
            {testimonial.map((item, key) => (
              <div
                key={key}
                className="testimonial-item position-relative bg-white rounded overflow-hidden p-4 shadow"
              >
                <p className="mb-3">{item.description}</p>
                <div className="d-flex align-items-center">
                  <img
                    className="img-fluid flex-shrink-0 rounded-circle"
                    src={item.img}
                    style={{ width: "45px", height: "45px" }}
                    alt={item.name}
                  />
                  <div className="ps-3">
                    <h6 className="fw-bold mb-1">{item.name}</h6>
                    <small>{item.profession}</small>
                  </div>
                </div>
                {item.icon}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
