import {
  doc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../../../model/FirebaseConfig";
import emailjs from 'emailjs-com';
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";

async function generateUniqueId() {
  // Reference to the "WebInquiry" collection
  const inquiryCollection = collection(db, "WebInquiry");

  // Query to get the last document by inquiryNumber in descending order
  const q = query(
    inquiryCollection,
    orderBy("inquiryNumber", "desc"),
    limit(1)
  );
  const querySnapshot = await getDocs(q);

  let newInquiryNumber = 1;

  if (!querySnapshot.empty) {
    // Get the last inquiryNumber and increment it
    const lastInquiryNumber = querySnapshot.docs[0].data().inquiryNumber;
    newInquiryNumber = lastInquiryNumber + 1;
  }

  // Format the new ID with leading zeros (e.g., "W-INQ-002")
  return {
    id: `W-INQ-${newInquiryNumber.toString().padStart(3, "0")}`,
    inquiryNumber: newInquiryNumber,
  };
}

async function saveInquiry(fullName, serviceType, phoneNumber, note) {
  try {
    const { id: inquiryId, inquiryNumber } = await generateUniqueId();
    const inquiryRef = doc(db, "WebInquiry", inquiryId);

    const inquiryData = {
      fullName,
      serviceType,
      phoneNumber,
      note,
      status: "open",
      timestamp: serverTimestamp(),
      inquiryNumber, // Store numeric part for sorting and ID generation
    };

    await setDoc(inquiryRef, inquiryData);

    generatePDF(fullName, inquiryId, serviceType, note, phoneNumber);

    // Send email using EmailJS
    emailjs
      .send(
        "service_7lbzl0s",
        "template_ib72m5e",
        {
          fullName: fullName,
          inquiryId: inquiryId,
          serviceType: serviceType,
          phoneNumber: phoneNumber,
          note: note,
          submissionTime: new Date().toLocaleString(),
        },
        "Md_TNagocsZEfruT_"
      )
      .then((result) => {
        console.log("Email sent successfully", result.status, result.text);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });

    Swal.fire({
      icon: "success",
      title: "Inquiry Submitted!",
      html: `
        <div class="receipt">
          <div class="receipt-header">
            <h2 class="company-name">OMAC GROUPS</h2>
            <p>Inquiry Receipt</p>
          </div>
          <div class="receipt-body">
            <p><strong>Name:</strong> ${fullName}</p>
            <p><strong>Service:</strong> ${serviceType}</p>
            <p><strong>Inquiry ID:</strong> ${inquiryId}</p>
            <p><strong>Date:</strong> ${new Date().toLocaleString()}</p>
          </div>
          <div class="receipt-footer">
            <p>Thank you for reaching out to us!</p>
            <p>We'll get back to you soon.</p>
          </div>
        </div>
      `,
      confirmButtonText: "OK",
      customClass: {
        popup: "receipt-popup",
      },
      showCloseButton: true,
      showConfirmButton: true,
      timer: 50000,
      timerProgressBar: true,
      didOpen: () => {
        const style = document.createElement("style");
        style.textContent = `
          .receipt-popup {
            background-color: #f8f9fa;
            border-radius: 15px;
            box-shadow: 0 0 20px rgba(0,0,0,0.1);
          }
          .receipt {
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0,0,0,0.05);
          }
          .receipt-header {
            text-align: center;
            border-bottom: 2px dashed #e9ecef;
            padding-bottom: 10px;
            margin-bottom: 15px;
          }
          .receipt-header h2.company-name {
            color: #28a745;
            font-size: 24px;
            margin: 0;
            font-weight: bold;
          }
          .receipt-body {
            margin-bottom: 15px;
          }
          .receipt-body p {
            margin: 5px 0;
            font-size: 14px;
          }
          .receipt-footer {
            text-align: center;
            border-top: 2px dashed #e9ecef;
            padding-top: 10px;
            font-size: 14px;
          }
        `;
        document.head.appendChild(style);
      },
    });

    return true; // Indicate successful submission
  } catch (error) {
    console.error("Error saving inquiry: ", error);
    Swal.fire({
      icon: "error",
      title: "Submission Failed",
      text: "There was an error saving your inquiry. Please try again later.",
    });
    return false; // Indicate failed submission
  }
}

function generatePDF(fullName, inquiryId, serviceType, note, phoneNumber) {
  const doc = new jsPDF();

  // Header
  doc.setFont("helvetica", "bold");
  doc.setFontSize(32);
  doc.setTextColor(22, 163, 74); // Dark green
  doc.text("OMAC GROUPS", 105, 20, { align: "center" });

  doc.setFontSize(18);
  doc.setTextColor(75, 85, 99); // Gray
  doc.text("Inquiry Confirmation", 105, 30, { align: "center" });

  // Content introduction
  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.setTextColor(55, 65, 81); // Slightly darker gray for content text
  doc.text(`Dear ${fullName},`, 20, 50);
  doc.text(
    "Thank you for reaching out to us through the OMAC web platform. We have successfully received your inquiry and are excited to assist you with your needs.",
    20,
    60,
    { maxWidth: 170 }
  );
  doc.text(
    "Our team is reviewing your request and will get back to you shortly. In the meantime, please find the details of your inquiry below:",
    20,
    75,
    { maxWidth: 170 }
  );

  // Table
  doc.autoTable({
    startY: 90,
    head: [["Field", "Value"]],
    body: [
      ["Inquiry ID", inquiryId],
      ["Full Name", fullName],
      ["Type of Service", serviceType],
      ["Phone Number", phoneNumber],
      ["Inquiry", note],
      ["Submitted Time", new Date().toLocaleString()],
    ],
    styles: { fontSize: 10, cellPadding: 8, textColor: [55, 65, 81] },
    headStyles: { fillColor: [22, 163, 74], textColor: [255, 255, 255] },
    alternateRowStyles: { fillColor: [240, 253, 244] },
    margin: { left: 20, right: 20 }, // Add margins to center the table
  });

  // Thank-you message section
  doc.setFillColor(240, 253, 244);
  doc.rect(20, doc.autoTable.previous.finalY + 10, 170, 30, "F"); // Adjust width and centering
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.setTextColor(21, 128, 61); // Green
  doc.text(
    "THANK YOU FOR CONTACTING OMAC GROUPS",
    105,
    doc.autoTable.previous.finalY + 20,
    { align: "center" }
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.setTextColor(55, 65, 81); // Gray
  doc.text(
    "We appreciate your interest and will get back to you soon.",
    105,
    doc.autoTable.previous.finalY + 30,
    { align: "center" }
  );
  doc.setFont("helvetica", "bold");
  doc.setTextColor(22, 163, 74);

  // Footer
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  doc.setFont("helvetica", "italic");
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setTextColor(107, 114, 128); // Dark gray for footer
    doc.text(
      "© 2023 OMAC GROUPS. All rights reserved.",
      105,
      doc.internal.pageSize.height - 20,
      { align: "center" }
    );
    doc.text(
      "This is an automated message. Please do not reply to this email.",
      105,
      doc.internal.pageSize.height - 10,
      { align: "center" }
    );
  }

  // Save the PDF
  doc.save(`Inquiry-receipt_${inquiryId}.pdf`);
}

export default saveInquiry;