// NewsletterHandler.js

import { db } from "../../../model/FirebaseConfig";
import { collection, doc, setDoc, getDocs, query, orderBy, limit } from "firebase/firestore";

// Function to generate a new ID based on the last document's ID
async function generateNewNewsletterId() {
  const newsletterRef = collection(db, "Newsletter");
  const q = query(newsletterRef, orderBy("id", "desc"), limit(1));
  
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const lastDoc = querySnapshot.docs[0];
    const lastId = lastDoc.id;
    
    // Extract the numeric part of the last ID and increment by 1
    const lastNumericId = parseInt(lastId.split("-")[1]);
    const newId = lastNumericId + 1;
    
    // Pad the new ID with leading zeros to maintain the format "NLS-XXX"
    return `NLS-${newId.toString().padStart(3, "0")}`;
  } else {
    // If there are no documents, start with "NLS-001"
    return "NLS-001";
  }
}

// Function to save the newsletter subscription with a dynamically generated ID
export async function saveNewsletterSubscription(email) {
  const newDocId = await generateNewNewsletterId();
  const docRef = doc(db, "Newsletter", newDocId);

  try {
    await setDoc(docRef, { id: newDocId, email, subscribedAt: new Date() });
  } catch (error) {
    console.error("Error saving subscription:", error);
    throw error;
  }
}
